import { id } from "date-fns/locale";

export const faqs = [
  {
    id: 'tramentos-ou-lag',
    question: 'Minha máquina está travando ou com lag.',
    answer: `
    • Para casos de travamentos verifique se as configurações estão corretas no aplicativo.<br>
    • Vá em configurações e escolha a opção mais recomendável (recomendamos melhor desempenho para não haver travamentos).<br>
    • Escolha a opção 60 FPS. Caso ainda mantenha travamentos, recomendamos abaixar para 30 FPS, pois isso significa que seu dispositivo não suporta mais.
    `
  },
  {
    id: 'uso-plano-mensal',
    question: 'Eu só posso jogar uma sessão por dia no plano mensal?',
    answer: `
    • Não, você pode jogar quantas sessões quiser, mas o plano Unit oferece horas com alta prioridade no plano. Após usar essas horas acabarem você terá menos prioridade nas filas e o tempo de sessão cairá para 2 horas.
    `
  },
  {
    id: 'uso-ios',
    question: 'Consigo usar no IOS (Iphone e Ipad)?',
    answer: `
    • Não, nosso aplicativo para IOS ainda está em desenvolvimento :(.
    `
  },
  {
    id: 'teclado-mobile',
    question: 'Como posso acessar o teclado virtual no celular/tablet?',
    answer: `
    • Para acessar o teclado no celular/tablet, basta clicar com tres dedos simultaneamente na tela.`
  },
  {
    id: 'resolucao-vms',
    question: 'Qual é a resolução dos computadores da NOAR.',
    answer: `
    • A resolução 8k está disponível em todos os planos.
    `
  },
  {
    id: 'plano-enterprise',
    question: 'Como funciona o plano Enterprise?',
    answer: `
    • Para saber mais detalhes sobre o plano Enterprise, entre em contato com nossa equipe de vendas pelo email comercial@noarcloud.com.
    `
  },
  {
    id: 'fivem-valorant-disponiveis',
    question: 'Posso jogar Fivem e Valorant?',
    answer: `
    • O FiveM possui um sistema anti-cheat que não permite seu funcionamento em máquinas virtuais. Você consegue jogar, mas corre riscos de ter a conta banida. O Valorant não roda em máquinas virtuais, assim como em outros jogos. Antes de comprar, verifique se seu jogo é compatível com máquinas virtuais.
    `
  },
  {
    id: 'rodar-qualquer-aplicativo',
    question: 'É possível rodar qualquer aplicativo/jogo?',
    answer: `
    • Com a NOAR é possível rodar qualquer aplicativo ou jogo compatível com os nossos sistemas. Verifique a compatibilidade.
    `
  },
  {
    id: 'formas-pagamento',
    question: 'Quais são as formas de pagamento disponíveis?',
    answer: `
    • Você pode pagar via PIX ou cartão de crédito.
    `
  },
  {
    id: 'dispositivos-compativeis',
    question: 'Em quais dispositivos eu consigo utilizar a NOAR?',
    answer: `
    • Utilizando nosso aplicativo Noar Connect você consegue se conectar a partir de computadores Windows, smartphones e tablets Android, Tv Box’s, entre outros. Para mais informações, acesse a página de download.
    `
  },
  {
    id: 'dados-salvos',
    question: 'Meus dados serão salvos quando desligar a máquina?',
    answer: `
    • No plano Unit não, sempre que finalizar a sessão, seus dados serão deletados imediatamente.<br>
    • Salvamento somente disponível no plano para empresas.
    `
  },
  {
    id: 'contabilizacao-horas',
    question: 'Como minhas horas são contabilizadas?',
    answer: `
   • Assim que você iniciar o computador suas horas já começam a contar, mesmo que você não se conecte ou utilize o computador.<br>
   • A conta só para de contar quando você desligar o computador.
   `
  },
  {
    id: 'instalacao-programas-jogos-piratas',
    question: 'Posso instalar programas e jogos piratas?',
    answer: `
   • A NOAR é contra o uso de programas piratas.
   `
  },
  {
    id: 'esqueci-computador-ligado',
    question: 'Esqueci o computador ligado e minhas horas sumiram.',
    answer: `
   • O uso das horas é de responsabilidade do usuário. Caso esqueça o computador ligado, as horas não serão devolvidas.
   `
  },
  {
    id: 'restricao-conteudo-menores',
    question: 'Existe restrição de conteúdo para menores de idade?',
    answer: `
   • Não, o uso da máquina é livre. Nós recomendamos o uso acompanhado para menores de idade.
   `
  },
  {
    id: 'limites-horas-dia',
    question: 'Tem limite de horas por dia?',
    answer: `
    • Não, o limite é por sessão, mas quando acaba o seu tempo, você pode entrar de novo e continuar usando.
    `
  },
  {
    id: 'perda-acesso-apos-horas-prioritarias',
    question: 'Após as horas prioritárias eu perco o acesso?',
    answer: `
    • Você nunca perde o acesso, quando acabam as horas prioritárias, você só perde a prioridade no acesso em caso de filas e seu tempo de sessão passa a ser de 2 horas por sessão.
    `
  },
  {
    id: 'plano-ilimitado',
    question: 'Tem plano ilimitado?',
    answer: `
    • Todos os planos são de uso ilimitado. Você poderá utilizar quantas vezes quiser!
    `
  },
  {
    id: 'renderizacao-treinamento-ia',
    question: 'Posso renderizar, ou fazer treinamento de IA?',
    answer: `
    • Para renderização temos planos específicos que podem ser contratados através dos nossos canais de atendimento. Os planos padrão não podem ser utilizados para renderização ou treinamento de IA.
    `
  },
  {
    id: 'horas-prioritarias',
    question: 'O que são as “horas prioritárias”?',
    answer: `
    • O sistema de horas prioritárias é um sistema que serve como parâmetro para definir qual é a sua prioridade no acesso e quanto tempo de sessão você terá.<br>
    • Quando acabam as horas prioritárias o seu tempo de sessão vai para 2 horas e a prioridade no acesso (somente em casos de fila) cai conforme o seu plano contratado seguindo a seguinte ordem: Ultra>Plus>Basic>Ultra sem prioridade>Plus sem prioridade>Basic sem prioridade>Gratuito. Como podem ver, o gratuito não tem nenhuma prioridade seguindo a ordem crescente de acordo com os planos.
    `
  },
  {
    id: 'arquivos-salvos',
    question: 'Os arquivos são salvos?',
    answer: `
    • No plano atual os arquivos sempre são excluídos quando a máquina é desligada. Uma forma de manter um jogo salvo é contratando o plano Ultra, que te dá direito de escolher um jogo ou programa até 80GB para ficar pré instalado.
    `
  }

];

export default faqs;
