import { BlueStrong, DefaultContentDivWrapper, DefaultContentParagraph, SectionTitle } from "../styled";

import * as S from './styled';

export function AboutBusiness() {
    return (
        <DefaultContentDivWrapper>
            <SectionTitle>Sobre a Noar</SectionTitle>
            <S.ContentWrapper>
                <DefaultContentParagraph>
                Se você busca <BlueStrong>reduzir custos e maximizar a produtividade</BlueStrong> da sua equipe, independente da sua localização, o plano Enterprise do Noar oferece a resposta ideal.
                </DefaultContentParagraph>
                <DefaultContentParagraph>
                Com o Noar Enterprise, sua empresa pode desfrutar dos benefícios da computação em nuvem <BlueStrong>sem preocupações</BlueStrong> com a equipe de implantação, substituições regulares de equipamentos, manutenção ou suporte à infraestrutura de cloud. Nós cuidamos de tudo isso por você, o que resulta em uma <BlueStrong>redução considerável de custos</BlueStrong>.
                </DefaultContentParagraph>
                <DefaultContentParagraph>
                Além disso, proporcionamos a possibilidade de ter máquinas virtuais com placas gráficas de alta performance. Com essa capacidade, sua equipe pode executar <BlueStrong>trabalhos intensivos</BlueStrong> em softwares de design, edição de imagens, inteligência artificial e vídeos, sem a necessidade de atualizar constantemente o hardware.
                </DefaultContentParagraph>
                <DefaultContentParagraph>
                O Noar Enterprise oferece uma solução de cloud computing que não apenas otimiza as operações da sua empresa, mas também <BlueStrong>garante um ambiente mais fluído, eficiente e seguro.</BlueStrong>
                </DefaultContentParagraph>
            </S.ContentWrapper>
        </DefaultContentDivWrapper>
    );
}

export default AboutBusiness;