import { DefaultContentDivWrapper, SectionTitle } from "../styled";
import * as S from "./styled";
import contactIcon from './icons/contact.svg';
import ready from './icons/ready.svg';
import plans from './icons/plans.svg';
import collabs from './icons/collabs.svg';

export function HowToUseBusiness() {

    return (
        <DefaultContentDivWrapper>
            <SectionTitle>
                Como usar?
            </SectionTitle>
            <S.HowToUseContent>
                <S.HowToUseItem>
                    <S.HowToUseIcon alt={''} src={contactIcon} width={20} height={20}/>
                    <S.HowToUseText>Entre em contato com a <strong>NOAR</strong></S.HowToUseText>
                </S.HowToUseItem>
                <S.HowToUseItem>
                    <S.HowToUseIcon alt={''} src={plans} width={20} height={20}/>
                    <S.HowToUseText>Adquira um de nossos <strong>planos</strong></S.HowToUseText>
                </S.HowToUseItem>
                {/* <S.HowToUseItem>
                    <S.HowToUseIcon alt={''} src={collabs} width={20} height={20}/>
                    <S.HowToUseText>Cadastre seus <strong>colaboradores</strong></S.HowToUseText>
                </S.HowToUseItem> */}
                <S.HowToUseItem>
                    <S.HowToUseIcon alt={''} src={ready} width={20} height={20}/>
                    <S.HowToUseText>Pronto. <strong>Aproveite!</strong></S.HowToUseText>
                </S.HowToUseItem>
            </S.HowToUseContent>
        </DefaultContentDivWrapper>

    );

}

export default HowToUseBusiness;