import linux from './linux.svg';
import mac from './mac.svg';
import windows from './windows.svg';

export const items = [
  {
    img: { src: windows, width: 67, height: 67 },
    link: 'https://images.noarcloud.com/noar-connect/noarConnectSetup-0.1.9.exe',
    name: 'Windows',
  },
  // {
  //   img: { src: mac, width: 59, height: 73 },
  //   link: 'https://github.com/moonlight-stream/moonlight-qt/releases/download/v5.0.1/Moonlight-5.0.1.dmg',
  //   name: 'Mac',
  // },
  // {
  //   img: { src: linux, width: 73, height: 86 },
  //   link: 'https://github.com/moonlight-stream/moonlight-qt/releases',
  //   name: 'Linux',
  // },
];

export default items;
