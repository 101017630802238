import { DefaultContentDivWrapper, SectionTitle } from '../styled';
import * as S from './styled';

export function Benefits() {
  return (
    <DefaultContentDivWrapper>
      <SectionTitle>Benefícios</SectionTitle>
      <S.BenefitItem>
        <S.BenefitLine color="#426FE5" />
        <S.BenefitContent>
          <S.BenefitTitle>GPU de alta performance</S.BenefitTitle>
          <S.BenefitP>
            A Noar disponibiliza máquinas com GPU dedicada para trabalhos gráficos pesados (animação, renderização 3D,
            edição de vídeo 4k e efeitos visuais).
          </S.BenefitP>
        </S.BenefitContent>
      </S.BenefitItem>
      <S.BenefitItem>
        <S.BenefitLine color="#5457F3" />
        <S.BenefitContent>
          <S.BenefitTitle>Redução de custos</S.BenefitTitle>
          <S.BenefitP>
            Elimine a necessidade de aquisição ou substituição de equipamentos caros de alto desempenho e operacional em
            manutenção.
          </S.BenefitP>
        </S.BenefitContent>
      </S.BenefitItem>
      <S.BenefitItem>
        <S.BenefitLine color="#713AF4" />
        <S.BenefitContent>
          <S.BenefitTitle>Facilidade e escalabilidade</S.BenefitTitle>
          <S.BenefitP>
            Disponibilize novas máquinas de alta capacidade para seus colaboradores com o painel de gerenciamento. Tudo
            em poucos minutos e alguns cliques.
          </S.BenefitP>
        </S.BenefitContent>
      </S.BenefitItem>
      <S.BenefitItem>
        <S.BenefitLine color="#A00BF6" />
        <S.BenefitContent>
          <S.BenefitTitle>Segurança e confiabilidade</S.BenefitTitle>
          <S.BenefitP>
            A Noar oferece segurança avançada para seus usuários, com backup regular de dados, criptografia de ponta a
            ponta e outras medidas de segurança de dados para proteger as informações confidenciais da empresa.
          </S.BenefitP>
        </S.BenefitContent>
      </S.BenefitItem>
      <S.BenefitItem>
        <S.BenefitLine color="#a847e1" />
        <S.BenefitContent>
          <S.BenefitTitle>Supercomputadores</S.BenefitTitle>
          <S.BenefitP>
            Acesso a supercomputadores com processadores Ryzen ou Threadripper, até 256GB de memória RAM,
            GPUs RTX40 series / RTX50 series / AMD7900XT / AMD7900XTX e tamanho de armazenamento de sua escolha.
          </S.BenefitP>
        </S.BenefitContent>
      </S.BenefitItem>
    </DefaultContentDivWrapper>
  );
}

export default Benefits;
