import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@Components/Public/Button';
import * as S from './styled';
import { desktopMinSize, mqmax } from '../../../styles/mixins';

export const Article = ({ descriptions, headingLevel, logo, name, price, title, ...props }) => {
  const as = `h${headingLevel}`;

  const openWhatsapp = () => {
    window.open("https://wa.me/5511964543593?text=Olá, gostaria de conhecer melhor a Noar B2B!",'_blank');
  }

  return (
    <article {...props}>
      <S.Div>
        <S.Title Title {...{ as }}>
          <strong>{title}</strong>
        </S.Title>
        <S.Hr />
        <S.List>
          {descriptions.map(textWithLineBreaks => (
            <S.ListItem
              key={textWithLineBreaks}
              dangerouslySetInnerHTML={{ __html: textWithLineBreaks.replace(/\n/g, '<br />') }}
            />
          ))}
        </S.List>
        <S.Hr />
        {/* <Price {...price} /> */}
        <S.Buttons>
          <S.P>
            <strong>comercial@noarcloud.com</strong>
          </S.P>
        </S.Buttons>
      </S.Div>
    </article>
  );
};

function ContainedButton(props) {
  return <Button variant="popup-contained" {...props} />;
}

function Price({ text, unity, value }) {
  return (
    <S.P>
      <S.Text>{text}</S.Text>
      <p>$USD <S.Value> {value} </S.Value></p>
    </S.P>
  );
}

export const SellPopupItem = styled(Article)`
  border: solid rgba(255, 255, 255, 20%) calc(2rem / 16);
  border-radius:  40px;
  position: sticky;
  width: 22rem;
  left: 65%;
  top: 22%;

  ${mqmax(desktopMinSize - 1)} {
    display: none;
  }
`;

export default SellPopupItem;
