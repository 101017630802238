import styled from "styled-components";
import { desktopMinSize, mq, mqmax, mqmin } from "../../styles/mixins";
import { Button as MuiButton } from '@material-ui/core';

export const Container = styled.main`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
    padding-block: calc(180rem / 16);  

    ${mq(desktopMinSize + 400, desktopMinSize)} {
        padding-block: calc(50rem / 16);  
    }

    ${mqmax(desktopMinSize -1)} {
        flex-direction: column;
        gap: 0;
        padding-block: calc(50rem / 16);
        max-width: 100%;
        align-items: center;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 40%;
    row-gap: 6rem;

    ${mqmax(desktopMinSize -1)} {
        max-width: 75%;
        row-gap: 4.5rem;
    }
`;

export const DefaultContentDivWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 2.5rem;

    ${mqmax(desktopMinSize -1)} {
        gap: 1.5rem;
    }
`;

export const Title = styled.h1`
    line-height: calc(48 / var(--fontSize));
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.8rem;
`;

export const StyledPurpleText = styled.span`
    background: linear-gradient(89.03deg, #426FE5 -2.1%, #7A2BE9 104.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
`;

export const Presentation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
`;

export const PresentationP = styled.p`
    max-width: 60%;
    line-height: 20px;

    ${mqmax(desktopMinSize -1)} {
        max-width: 100%;
    }
`;

export const PresentationButton = styled(MuiButton)`
    &.MuiButton-root {
        border: solid #2d80f2 calc(2rem / 16);
        border-radius: calc(24rem / 16);
        padding: calc(9rem / 16) calc(30rem / 16);
        color: #2d80f2;
        opacity: ${({ disabled }) => disabled ? 0.5 : 1};
        background: transparent;
        font-weight: bold;
        line-height: calc(18 / var(--fontSize));
        transition: ${['color', 'background'].map(prop => `${prop} 300ms ease-out`).join(', ')};

        &:hover {
            color: #fff;
            background: #2d80f2;
        }          

        ${mqmin(desktopMinSize)} {
            line-height: calc(16 / var(--fontSize));
        }
    }
`;

export const SectionTitle = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 52px;

    ${mqmax(desktopMinSize -1)} {
        font-size: 28px;
        line-height: 38px;
    }
`;

export const BlueStrong = styled.strong`
    color: #426FE5;
`;

export const DefaultContentParagraph = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 160.5%;

    & > strong {
        font-weight: 900;
    }
`;